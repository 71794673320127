import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";
import { SupabaseClient } from '@supabase/supabase-js'
import { supabaseIntegration } from '@supabase/sentry-js-integration'
import { InitAnalytics } from './lib/analytics';

Sentry.init({
  dsn: "https://6034681aa57ad380541845310400f7cb@o4508685314228224.ingest.us.sentry.io/4508685471907840",
  integrations: [
    Sentry.httpClientIntegration(),
    Sentry.httpContextIntegration(),
    supabaseIntegration(SupabaseClient, Sentry, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    })
  ],
});

InitAnalytics({ apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY || "" });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
