import * as amplitude from '@amplitude/analytics-browser';

interface AnalyticsInitializationContext {
  apiKey: string;
}

export const InitAnalytics = (context: AnalyticsInitializationContext) => {
  amplitude.init(context.apiKey);
}

interface AnalyticsEvent {
  name: string;
  properties?: Record<string, any>;
}

export const TrackAnalyticsEvent = (event: AnalyticsEvent) => {
  try {
    amplitude.track(event.name, event.properties);
  } catch (error) {
    console.error('Error tracking event:', event.name, event, error);
  }
}

interface AnalyticsUserContext {
  userId: string;
  email: string;
  name: string;
  organisation: string;
  organisationId: string;
}

export const IdentifyAnalyticsUser = (context: AnalyticsUserContext) => {
  try {
    console.log('Identifying user:', context);
    const userInfo = new amplitude.Identify();
    userInfo.set('userId', context.userId);
    userInfo.set('email', context.email);
    userInfo.set('name', context.name);
    userInfo.set('organisation', context.organisation);
    userInfo.set('organisationId', context.organisationId);
    amplitude.setUserId(context.userId);
    amplitude.identify(userInfo);
  } catch (error) {
    console.error('Error identifying user:', context.userId, error);
  }
}
