import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { TimeSelectOptions, cn, closestTimeToNow } from "@/lib/utils"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { CalendarIcon } from "@radix-ui/react-icons"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import moment from "moment"
import { NewMaintenanceLog } from "../table/columns"
import { FieldType } from "../Maintenance"
import { Machine } from "@/pages/machines/list/table/columns"
import { useEffect } from "react"

interface MaintenanceFormProps {
  onClose: () => void
  onFormSuccess: (values: z.infer<typeof formSchema>) => void
  maintenanceLog?: NewMaintenanceLog,
  types: FieldType[]
  statuses: FieldType[]
  systems: FieldType[]
  equipment: Machine[]
}

export const formSchema = z.object({
  id: z.string().optional(),
  site_id: z.string().optional(),
  equipment_id: z.string({
    required_error: 'Site ID is required'
  }),
  work_order: z.string().optional(),
  work_order_description: z.string().optional(),
  description: z.string().optional(),
  system_id: z.string().optional().nullable(),
  downtime_type_id: z.string({
    required_error: 'Downtime Type is required'
  }).trim().min(1, 'Downtime Type is required'),
  status_id: z.string({
    required_error: 'Status is required'
  }).trim().min(1, 'Status is required'),
  smu: z.string().optional(),
  date_down: z.date({
    required_error: 'Date Down is required'
  }),
  time_down: z.string({
    required_error: 'Time Down is required'
  }),
  date_up: z.date().optional(),
  time_up: z.string().optional()
});

export function MaintenanceForm({ onClose, onFormSuccess, maintenanceLog, types, statuses, systems, equipment }: MaintenanceFormProps) {
  const closestTimeUp = closestTimeToNow(TimeSelectOptions);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      date_down: moment().toDate(),
      time_down: closestTimeUp
    },
  })

  useEffect(() => {
    if (!maintenanceLog || !form) return;
    form.reset({
      id: maintenanceLog.id,
      equipment_id: maintenanceLog.equipment_id,
      site_id: maintenanceLog.site_id,
      work_order: maintenanceLog.work_order || "",
      work_order_description: maintenanceLog.work_order_description || "",
      description: maintenanceLog.description || "",
      system_id: maintenanceLog.system_id,
      downtime_type_id: maintenanceLog.downtime_type_id,
      status_id: maintenanceLog.status_id,
      smu: maintenanceLog.smu || "",
      date_down: maintenanceLog.date_down ? moment(maintenanceLog.date_down, 'YYYY-MM-DD').toDate() : undefined,
      time_down: maintenanceLog.time_down ? moment(maintenanceLog.time_down, 'HH:mm').format('hh:mm A') : undefined,
      date_up: maintenanceLog.date_up ? moment(maintenanceLog.date_up, 'YYYY-MM-DD').toDate() : undefined,
      time_up: maintenanceLog.time_up ? moment(maintenanceLog.time_up, 'HH:mm').format('hh:mm A') : undefined
    })
  }, [maintenanceLog, form]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    onFormSuccess(values);
  }

  const handleClose = () => {
    form.reset();
    onClose();
  }

  return (
    <Form {...form}>
      <form className="space-y-2">
        <div className="flex gap-2 items-stretch">
          <FormField
            control={form.control}
            name="equipment_id"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Site ID</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Site ID" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {equipment.map((item) => (<SelectItem key={item.id} value={item.id || ""}>{item.site_id}</SelectItem>))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="smu"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>SMU</FormLabel>
                <FormControl>
                  <Input placeholder="Enter the machine SMU" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2 items-stretch">
          <FormField
            control={form.control}
            name="system_id"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>System</FormLabel>
                <Select onValueChange={field.onChange} value={field.value || undefined}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a System" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {systems.map((system) => (<SelectItem key={system.id} value={system.id}>{system.name}</SelectItem>))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="status_id"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>Status</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {statuses.map((status) => (<SelectItem key={status.id} value={status.id}>{status.name}</SelectItem>))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="work_order"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Work Order #</FormLabel>
              <FormControl>
                <Input placeholder="Enter the work order Number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="downtime_type_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Downtime Type</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a Type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {types.map((type) => (<SelectItem key={type.id} value={type.id}>{type.name}</SelectItem>))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="Describe the job"
                className="resize-none"
                rows={6}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="work_order_description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Work Order Description</FormLabel>
              <Textarea
                placeholder="Describe the work order"
                className="resize-none"
                rows={3}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex gap-2 items-stretch">
          <FormField
            control={form.control}
            name="date_down"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Date Down</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          moment(field.value).format("DD/MM/YY")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="time_down"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Time Down</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Time" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {TimeSelectOptions.map((time) => (<SelectItem key={time} value={time}>{time}</SelectItem>))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-2 items-stretch">
          <FormField
            control={form.control}
            name="date_up"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Date Up</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-[240px] pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          moment(field.value).format("DD/MM/YY")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="time_up"
            render={({ field }) => (
              <FormItem className="flex flex-col flex-1">
                <FormLabel>Time Down</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Time" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {TimeSelectOptions.map((time) => (<SelectItem key={time} value={time}>{time}</SelectItem>))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex">
          <Button type="button" onClick={handleClose} variant="ghost">Cancel</Button>
          <Button type="submit" onClick={form.handleSubmit(onSubmit)} className="ml-auto">Submit</Button>
        </div>
      </form >
    </Form >
  )
}